.home-first-block {
    width: 100vw;
    height: 700px;
    margin-top: 80px;
}

.home-first-block-content {
    width: 80%;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.home-first-block-content-item {
    flex: 1;
    height: 100%;
}

.home-first-block-content-item h2 {
    /*Titulo Principal*/
    color: var(--title-color);
    text-align: left;
    font-size: 4.5rem;
    margin-top: 50px;
    width: 90%;
}

.home-first-block-content-item p {
    /*Texto titulo Principal*/
    color: var(--text-color);
    text-align: left;
    font-size: 1.5rem;
    margin-top: 40px;
    width: 90%;
}

.home-first-block-content-item img {
    width: 92%;
    margin-top: 0px;
}

.home-first-block-content-item a {
    /*Botao Começar agora*/
    padding: 12px;
    width: 150px;
    border-radius: 30px;
    font-size: 1rem;
    font-weight: bold;
    color: var(--background-color);
    /* Cor botao Começar agora*/
    background: var(--button-title-color);
    border: 2px solid var(--button-title-color);
    transition: .5s;
    cursor: pointer;
    text-decoration: none;
}

.home-first-block-content-item a:hover {
    /*Borda Saber*/
    border-color: var(--button-title-color) !important;
    background: transparent !important;
    transition: .5s;
    color: var(--button-title-color) !important;
}

.home-first-block-simple-button {
    /*Texto dentro do borda saber*/
    background: transparent !important;
    margin-left: 20px;
    color: var(--title-color) !important;
    border-color: transparent !important;
}

.home-first-block-content-item-links {
    margin-top: 60px;
    display: flex;
    align-items: flex-start;
}

.home-first-block-content-item h6 {
    color: var(--title-color);
    font-size: 1.2rem;
    text-align: left;
    opacity: .9;
    font-weight: 400;
    margin-top: 20px;
}

.home-second-block {
    width: 100vw;
    height: 100px;
    margin-top: 20px;
}

.home-second-block-content {
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50%;
    transform: translateX(-50%);
}

.home-second-block-content-item {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
}

.home-second-block-content-item img {
    width: 10%;
    filter: grayscale(100%);
    margin-top: 30px;
}

.home-third-block {
    width: 100vw;
    height: 1100px;
    margin-top: 150px;
}

.home-third-block-content {
    width: 70vw;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
}

.home-third-block-header {
    width: 100%;
    height: 25%;
}

.home-third-block-header h2 {
    width: 100%;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 20px;
    color: var(--title-color);
    font-size: 2.4rem;
}

.White-Service {
    color: var(--button-title-color);
}

.home-third-block-header p {
    font-size: 1.16rem;
    color: var(--text-color);
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
    font-weight: 400;
    overflow: hidden;
}

.home-third-block-itens {
    width: 100%;
    height: 32%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.home-third-block-item {
    flex: 1;
    height: 100%;
    /* Ajustado para auto para melhor responsividade */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* Centralizado para uniformidade */
    margin: 10px;
    /* Adicionado margem para espaçamento entre itens */
}

.home-third-block-card {
    width: 100%;
    /* Ajustado para 100% para melhor responsividade */
    max-width: 300px;
    /* Adicionado limite máximo de largura */
    height: 350px;
    /* Altura fixa para uniformidade */
    border-radius: 30px;
    border: 3px solid var(--title-color);
    transition: .5s;
    cursor: pointer;
    overflow: hidden;
    /* Adicionado para evitar que conteúdos longos quebrem o layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* Centralizado para uniformidade */
}

.home-third-block-card:hover {
    /* Adicionado limite máximo de altura */
    transform: scale(1.1);
    /* Ajustado para uma transição mais suave */
    transition: .5s;
}

.home-third-block-card-icon {
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-third-block-card-icon i {
    /* Ícones produtos */
    font-size: 2rem;
    border-radius: 15px;
    padding: 15px;
    background: var(--button-title-color);
    color: var(--background-color);
}

.home-third-block-card-text {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* Centralizado para uniformidade */
}

.home-third-block-card-text h1 {
    /* Nome do Produto */
    color: var(--button-title-color);
    margin-top: 10px;
    margin-bottom: 10px;
    /* Adicionada margem inferior */
    font-size: 1.5rem;
    /* Tamanho uniforme */
}

.home-third-block-card-text p {
    margin-top: 10px;
    margin-bottom: 10px;
    /* Adicionada margem inferior */
    font-size: 1.1rem;
    color: var(--text-color);
    width: 90%;
    overflow: hidden;
    /* Adicionado para evitar que textos longos quebrem o layout */
}

.home-fourth-block {
    /*Faixa final */
    margin-top: 100px;
    margin-bottom: 100px;
    background: var(--title-color);
    height: 400px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-fourth-block-content {
    width: 65vw;
    height: 60%;
}

.home-fourth-block-content h3 {
    text-align: left;
    color: var(--background-color);
    font-size: 2rem;
    font-weight: 500;
    margin-top: 10px;
}

.home-fourth-block-content a {
    margin-top: 70px;
    width: 200px;
    padding: 15px;
    border: 5px solid var(--background-color);
    background: var(--background-color);
    border-radius: 100px;
    color: var(--title-color);
    font-size: 1.5rem;
    font-weight: bold;
    float: left;
    cursor: pointer;
    transition: .5s;
    text-decoration: none;
}

.home-fourth-block-content a:hover {
    background: transparent;
    color: var(--background-color);
    transition: .5s;
}

@media(max-width: 1600px) {
    .home-first-block {
        height: 650px;
    }
    .home-first-block-content-item h2 {
        font-size: 3.5rem;
    }
    .home-first-block-content-item p {
        font-size: 1rem;
    }
    .home-first-block-content-item img {
        width: 100%;
    }
}

@media(max-width: 1500px) {
    .home-first-block-content-item img {
        width: 110%;
    }
}

@media (max-width: 1400px) {
    .home-third-block-header h5 {
        font-size: 1rem;
    }
    .home-third-block-header h2 {
        font-size: 1.8rem;
    }
    .home-third-block-header p {
        font-size: 1rem;
    }
}

@media(max-width: 1200px) {
    .home-first-block {
        height: 500px;
    }
    .home-first-block-content-item h2 {
        font-size: 2rem;
    }
    .home-first-block-content-item img {
        width: 120%;
    }
    .home-first-block-content-item p {
        font-size: .9rem;
    }
    .home-first-block-content-item h6 {
        font-size: .8rem;
    }
    .home-first-block-content-item-links a {
        font-size: .9rem;
    }
}

@media(max-width: 1100px) {
    .home-first-block {
        height: 850px;
    }
    .home-first-block-content {
        flex-direction: column;
    }
    .home-first-block-content-item:nth-child(2) {
        margin-top: 100px;
    }
    .home-first-block-content-item img {
        width: 100%;
    }
    .home-second-block {
        height: 500px;
    }
    .home-second-block-content {
        flex-direction: column;
    }
    .home-second-block-content-item img {
        width: 120%;
    }
    .home-third-block {
        height: 2700px;
    }
    .home-third-block {
        margin-top: 100px;
    }
    .home-third-block-header h2 {
        font-size: 1.5rem;
        width: 100%;
    }
    .home-third-block-header {
        height: 300px;
    }
    .home-third-block-header p {
        width: 100%;
    }
    .home-third-block-itens {
        flex-direction: column;
        height: 1200px;
    }
    .home-third-block-item:nth-child(1) {
        justify-content: center;
    }
    .home-third-block-item:nth-child(2) {
        justify-content: center;
    }
    .home-third-block-item:nth-child(3) {
        justify-content: center;
    }
    .home-third-block-content {
        width: 90vw;
    }
    .home-third-block-card {
        width: 85%;
    }
    .home-fourth-block {
        margin-top: 200px;
        height: 550px;
    }
    .home-fourth-block-content {
        width: 80vw;
    }
    .home-fourth-block-content h3 {
        font-size: 1.7rem;
        text-align: center;
        margin-top: 50px;
    }
    .home-fourth-block-content a {
        margin-left: 50%;
        transform: translateX(-50%);
        font-size: 1.2rem;
        width: 150px;
    }
}