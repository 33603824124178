    header {
        margin-bottom: 30px;
    }
    
    .default-header-content {
        display: flex;
        height: 80px;
        width: 90vw;
        margin-left: 50%;
        transform: translateX(-50%);
        top: 0;
    }
    
    .header-logo {
        flex: 5;
        display: flex;
        height: 100%;
        align-items: center;
        margin-left: 3%;
        text-decoration: none;
        margin-left: 120px;
        justify-content: flex-start;
        margin-top: 10px;
    }
    
    .header-logo img {
        width: 70px;
        height: auto;
        transition: transform 0.3s ease;
    }
    
    .header-logo img:hover {
        transform: scale(1.1);
    }
    
    .header-logo-title-init {
        font-size: 1.9rem;
        margin-left: 5px;
        margin-top: 20px;
    }
    
    .header-logo-title {
        color: var(--title-color);
    }
    
    .header-logo-title-init {
        color: var(--button-color);
    }
    
    .dark-title {
        color: var(--title-color);
    }
    
    .header-itens {
        flex: 5;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .header-item {
        flex: 1;
        margin-left: 15px;
        margin-right: 15px;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    
    .header-item a {
        text-decoration: none;
        font-weight: 550;
        font-size: .98rem;
        color: var(--title-color);
        padding: 12px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 20px;
        transition: .5s;
        border: 1px solid transparent;
    }
    
    .header-item a:hover {
        cursor: pointer;
        transition: .5s;
        background: var(--title-color);
        color: white;
    }
    
    .header-last-item {
        margin-right: 10%;
    }
    
    .header-last-item a:hover {
        background: transparent;
        color: var(--title-color) !important;
        border: 1px solid var(--title-color);
    }
    
    .header-highlight-item {
        background: var(--title-color);
        color: var(--background-color) !important;
        border: 1px solid transparent;
    }
    
    .responsive-header-content {
        display: none;
        height: 50px;
        width: 100vw;
        top: 0;
    }
    
    .responsive-logo {
        flex: 5.5;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
        padding-top: 3px;
    }
    
    .responsive-logo img {
        width: 60px;
        margin-left: 20px;
    }
    
    .responsive-logo h3 {
        font-size: 1.2rem;
        margin-left: 10px;
    }
    
    .responsive-header-spread-menu {
        flex: 1.5;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .responsive-header-spread-menu i {
        color: var(--title-color);
        font-size: 1.5rem;
        cursor: pointer;
    }
    
    .responsive-menu {
        width: 100vw;
        height: 100vh;
        position: fixed;
        display: block;
        background: rgba(0, 0, 0, .85);
        transition: .5s;
        display: none;
        z-index: 5;
    }
    
    .responsive-menu-content {
        width: 70vw;
        height: 100vh;
        background: var(--background-color);
        position: fixed;
        margin-left: -100vw;
        transition: .5s;
        z-index: 10;
    }
    
    .responsive-menu-close {
        width: 98.3vw;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    
    .responsive-menu-close span {
        font-size: 1.3rem;
        font-weight: bold;
        margin-right: 30px;
        color: var(--background-color);
        cursor: pointer;
    }
    
    .responsive-menu-itens {
        width: 100%;
        height: 80%;
    }
    
    .responsive-menu-item {
        height: 50px;
        width: 80%;
        margin-left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-top: 1px solid rgba(0, 0, 0, .15);
        cursor: pointer;
    }
    
    .responsive-menu-item a {
        text-decoration: none;
        color: var(--button-title-color);
        font-size: 1.1rem;
        font-weight: 700;
        cursor: pointer;
        transition: transform 0.3s ease;
    }
    
    .responsive-menu-item a:hover {
        transform: scale(1.2);
        color: var(--title-color);
    }
    
    @media (max-width: 1100px) {
        .default-header-content {
            display: none !important;
        }
        .responsive-header-content {
            display: flex;
        }
    }