footer {
    width: 90vw;
    height: 350px;
    bottom: 0;
    margin-left: 50%;
    transform: translateX(-50%);
    position: relative;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-itens {
    flex: .95;
    height: 90%;
    margin-top: 20px !important;
    margin-bottom: 20px;
    padding-top: 50px;
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.footer-first-item {
    flex: 1.3 !important;
    margin-left: 100px !important;
}

.footer-first-item img {
    width: 120px;
    float: left;
}

.footer-first-item a {
    text-decoration: none;
}

.header-logo-title {
    color: var(--title-color);
}

.header-logo-title-init {
    color: var(--button-color);
}

.footer-first-item p {
    margin-top: 80px !important;
    width: 300px;
    color: var(--text-color) !important;
    text-align: justify;
}

.footer-first-item i {
    padding: 10px;
    margin-left: 10px;
    background: var(--button-title-color);
    color: var(--background-color);
    border-radius: 20px;
    cursor: pointer;
    transition: .5s;
    font-size: 1.2rem;
}

.footer-first-icon {
    margin-left: 0px !important;
}

.footer-first-item i:hover {
    background: var(--title-color);
    transition: .5s;
}

.footer-item {
    flex: 1;
    height: 100%;
}

.footer-item p {
    margin-top: 20px;
    font-size: .9rem;
    color: var(--text-color);
}

.footer-item input {
    margin-top: 10px;
    padding: 15px;
    border: 2px solid var(--button-title-color);
    border-radius: 5px;
    width: 300px;
    font-size: .8rem;
    color: var(--button-title-color);
}

.footer-item button {
    margin-top: 25px;
    padding: 15px;
    background: var(--button-title-color);
    color: var(--background-color);
    font-size: .9rem;
    border-radius: 30px;
    font-weight: bold;
    transition: .5s;
}

.footer-item button:hover {
    cursor: pointer;
    background: var(--title-color);
    transition: .5s;
}

.footer-item h4 {
    font-weight: bold;
    color: var(--title-color);
    font-size: 1.2rem;
}

.footer-item ul {
    margin-top: 20px;
}

.footer-item li {
    margin-top: 20px;
    font-size: 1.1rem;
    font-weight: 400;
    list-style-type: none;
    color: var(--text-color);
    opacity: 1;
}

.footer-item li a {
    text-decoration: none;
}

.footer-item li a:visited {
    color: var(--text-color) !important;
}

.footer-item li a:active {
    color: var(--button-title-color) !important;
}

.footer-item li a:hover {
    cursor: pointer;
    text-decoration: underline;
    color: var(--title-color) !important;
}

.footer-icons {
    margin-top: 20px;
    width: 300px;
    height: 40px;
}

@media (max-width: 1500px) {
    .footer-first-item p {
        width: 80%;
    }
}

@media(max-width: 1100px) {
    footer {
        height: 1300px;
    }
    .footer-itens {
        flex-direction: column;
    }
    .footer-first-item {
        margin-left: 0px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 0px !important;
    }
    .footer-first-item p {
        margin-top: 30px !important;
        font-size: 1.1rem !important;
    }
    .footer-item {
        margin-top: 80px;
    }
    .footer-item h4 {
        margin-left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
    .footer-item ul {
        margin-left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
    .footer-item p {
        text-align: center;
    }
    .footer-item input {
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 20px;
        width: 70%;
    }
    .footer-item button {
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .footer-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
}