:root {
    --background-color: #FFFFFF;
    --title-color: #4a7c2b;
    --text-color: #474747;
    --button-color: #7ac142;
    --text-slogam: #4a7c2b;
    --button-title-color: #7ac142;
    --button-title-color-menu: #4a7c2b;
    --focused-button-color: #f3eff7;
    --focused-button-color-title: #b8df9b83;
    --text-card: #0a350aa6;
    --opacity-card: #000000E6;
    --color-card-review: #152b083b;
}

* {
    padding: 0;
    margin: 0;
    border: 0;
    font-family: Ubuntu;
    font-stretch: normal;
}

html {
    overflow-x: hidden !important;
}

body {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    background-color: var(--background-color);
}