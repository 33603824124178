.review-first-block {
    width: 100vw;
    height: 1700px;
    margin-top: 100px;
}

.review-first-block-content {
    width: 75vw;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
}

.review-first-block-content h2 {
    color: var(--title-color);
    font-size: 2rem;
    text-align: left;
    height: 50px;
}

.review-first-block-itens {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.review-first-block-item {
    flex: 1;
    height: 400px;
    margin-left: 20px;
    border: 3px solid var(--title-color);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    transition: .5s;
}

.review-first-block-item:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: .5s;
}

.review-first-block-item p {
    text-align: left;
    color: var(--text-color);
    font-size: 1.2rem;
    margin-top: 10px;
    width: 80%;
}

.review-first-block-item img {
    width: 45%;
    margin-top: 30px;
    border-radius: 100%;
}

.review-first-block-item h5 {
    color: var(--title-color);
    margin-top: 30px;
    font-size: 1.3rem;
}

.review-first-block-item h6 {
    color: var(--button-title-color);
    font-size: 1.1rem;
}

.review-first-block-item:nth-child(1) {
    margin-left: 0px;
}

@media (max-width: 1100px) {
    .review-first-block-item p {
        font-size: 1rem;
    }
    .review-first-block-item h5 {
        font-size: 1.1rem;
    }
    .review-first-block-item h6 {
        font-size: .9rem;
    }
    .review-first-block-item img {
        width: 60% !important;
    }
}

@media (max-width: 900px) {
    .review-first-block {
        height: 6100px;
    }
    .review-first-block-itens {
        height: 2000px;
        flex-direction: column;
        margin-top: 0;
    }
    .review-first-block-item {
        margin-top: 80px;
    }
    .review-first-block-item img {
        width: 45% !important;
    }
}