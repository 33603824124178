.about-page {
    width: 100vw;
    height: auto;
}

.about-first-block {
    width: 95vw;
    height: 300px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    display: flex;
}

.about-first-block-item {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-first-block-item h2 {
    color: var(--title-color);
    font-size: 3rem;
    text-align: left;
    width: 65%;
}

.about-first-block-highlight {
    color: var(--button-title-color);
    font-weight: bold;
}

.about-first-block-item p {
    width: 50%;
    text-align: left;
    font-size: 1.5rem;
    color: var(--text-color);
    margin-left: 18%;
}

.about-second-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    height: 600px;
    width: 95vw;
    margin-left: 50%;
    transform: translateX(-50%);
}

.about-second-block img {
    width: 40%;
    margin-top: 0px !important; 
    
}

.about-third-block {
    width: 90vw;
    height: 1100px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 100px;
}

.about-third-block-content {
    width: 70%;
    height: 100%;
    border-top: 1px solid var(--text-card);
    margin-left: 50%;
    transform: translateX(-50%);
}

.about-third-block-text {
    display: flex;
    width: 100%;
    height: 80%;
}

.about-third-block-text-item {
    flex: 1;
    height: 100%;
}

.about-third-block-text-title-item {
    height: 10%;
}

.about-third-block-text-title-item h2 {
    margin-top: 50px;
    text-align: left;
    font-size: 2.5rem;
    width: 70%;
    color: var(--title-color);
    margin-left: 50%;
    transform: translateX(-50%);
}

.about-third-block-text-item h6 {
    margin-top: 50px;
    width: 70%;
    text-align: left;
    font-size: 1.4rem;
    color: var(--title-color);
    margin-left: 50%;
    transform: translateX(-50%);
}

.about-third-block-text-item p {
    text-align: left;
    width: 70%;
    color: var(--text-color);
    font-size: 1.2rem;
    text-align: left;
    margin-left: 50%;
    transform: translateX(-50%);
}

.about-third-block-text-item h3 {
    text-align: left;
    width: 70%;
    color: var(--button-title-color);
    font-size: 1.6rem;
    text-align: left;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 50px;
}

.about-third-block-numbers {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--text-card);
}

.about-third-block-numbers-item {
    flex: 1;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    height: 90%;
}

.about-third-block-numbers-item p {
    width: 70%;
    color: var(--text-color);
    font-size: 1.1rem;
    text-align: left;
}

.about-third-block-numbers-item h2 {
    color: var(--title-color);
    margin-top: 20px;
    font-size: 3.5rem;
    text-align: left;
}

.about-fourth-block {
    margin-top: 100px;
    margin-bottom: 100px;
    background: var(--title-color);
    height: 400px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-fourth-block-content {
    width: 65vw;
    height: 60%;
}

.about-fourth-block-content h3 {
    text-align: left;
    color: var(--background-color);
    font-size: 2rem;
    font-weight: 500;
    margin-top: 10px;
}

.about-fourth-block-content a {
    margin-top: 70px;
    width: 200px;
    padding: 15px;
    border: 5px solid white;
    background: var(--background-color);
    border-radius: 100px;
    color: var(--title-color);
    font-size: 1.5rem;
    font-weight: bold;
    float: left;
    cursor: pointer;
    transition: .5s;
    text-decoration: none;
}

.about-fourth-block-content a:hover {
    background: transparent;
    color: var(--background-color);
    transition: .5s;
}

@media(max-width: 1650px) {
    .about-third-block-content {
        width: 100%;
    }
    .about-second-block img {
        width: 60% !important;
    }
}

@media(max-width: 1100px) {
    .about-first-block {
        flex-direction: column;
        height: 550px;
    }
    .about-first-block-item h2 {
        font-size: 3rem;
        width: 90%;
    }
    .about-first-block-item p {
        font-size: 1.3rem;
        width: 80%;
        margin-left: 0px !important;
    }
    .about-second-block {
        align-items: flex-start;
        height: 250px;
    }
    .about-second-block img {
        width: 50% !important;
        margin-top: 0px;
    }
    .about-third-block {
        height: 1200px;
    }
    .about-third-block-text {
        flex-direction: column;
    }
    .about-third-block-content {
        width: 90%;
    }
    .about-third-block-text-title-item {
        height: 200px !important;
    }
    .about-third-block-text {
        height: 50%;
    }
    .about-third-block-text-title-item h2 {
        width: 100%;
    }
    .about-third-block-text-item h3 {
        width: 100%;
    }
    .about-third-block-text-item h6 {
        width: 100%;
        font-size: 1.3rem;
    }
    .about-third-block-text-item p {
        width: 100%;
    }
    .about-third-block-numbers {
        flex-direction: column;
        height: 40%;
        margin-top: 280px;
    }
    .about-third-block-numbers-item {
        height: 20%;
        align-items: center;
    }
    .about-third-block-numbers-item p {
        text-align: center;
    }
    .about-fourth-block {
        margin-top: 200px;
        height: 550px;
    }
    .about-fourth-block-content {
        width: 80vw;
    }
    .about-fourth-block-content h3 {
        font-size: 1.7rem;
        text-align: center;
        margin-top: 50px;
    }
    .about-fourth-block-content a {
        margin-left: 50%;
        transform: translateX(-50%);
        font-size: 1.2rem;
        width: 150px;
    }
}

@media(max-width: 900px) {
    .about-second-block img {
        width: 70% !important;
    }
}

@media(max-width: 600px) {
    .about-second-block img {
        width: 100% !important;
    }
    .about-third-block {
        height: 1600px;
    }
}